import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { isPermitted } from "./utils/PermissionManager";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";

const Confirmation = lazy(() => import("./pages/PaymentConfirmation"));
const Vendors = lazy(() => import("./pages/Vendors"));
const VendorBank = lazy(() => import("./pages/VendorBank"));
const Banks = lazy(() => import("./pages/Banks"));
const Bookings = lazy(() => import("./pages/Bookings"));
const CreateBooking = lazy(() => import("./pages/CreateBooking"));
const BookingDetails = lazy(() => import("./pages/BookingDetails"));
const VendorCounter = lazy(() => import("./pages/VendorCounter"));
const CustomerCounter = lazy(() => import("./pages/CustomerCounter"));

const Payment = lazy(() => import("./pages/Payment"));
const SegmentReport = lazy(() => import("./pages/SegmentReport"));
const PaymentReport = lazy(() => import("./pages/PaymentReport"));
const Invoice = lazy(() => import("./pages/Invoice"));

const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const Customers = lazy(() => import("./pages/Customers"));
const Login = lazy(() => import("./pages/Login"));
const Admins = lazy(() => import("./pages/Admins"));
const Payments = lazy(() => import("./pages/Payments"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage.travel_accounting) {
  setAuthToken(localStorage.travel_accounting);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const id = store.getState().auth?.profile?._id;

  const host = window.location.host;

  // if (host !== "accounting.tourmart.net" && host !== "localhost:3069") {
  //   return (
  //     <div
  //       className="flex align-center justify-center flexColumn"
  //       style={{ height: "100vh", width: "100%", background: "#fff" }}
  //     >
  //       <img src={require("./assets/locked.png")} style={{ width: 220 }} />
  //       <h2 className="mt1">Site Locked</h2>
  //       <p>This website is locked please contact authorized person.</p>
  //     </div>
  //   );
  // }

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#23005a",
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {id && <Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Dashboard />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/customers"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Customers />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/booking"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Bookings />}
                      />
                    }
                  />
                  {isPermitted(roles, ["admin", "reservation_officer"]) && (
                    <Route
                      exact
                      path="/manage-booking/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CreateBooking />}
                        />
                      }
                    />
                  )}
                  <Route
                    exact
                    path="/booking-details/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<BookingDetails />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/vendors"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Vendors />}
                      />
                    }
                  />
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/vendor-counter"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<VendorCounter />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/customer-counter"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CustomerCounter />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/banks"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Banks />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/payments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payments />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/report/booking"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MonthlyReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/report/segment"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SegmentReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/report/payment"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<PaymentReport />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/vendor-bank/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<VendorBank />}
                      />
                    }
                  />
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/payment/:type/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payment />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/payment/confirmation/:key"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Confirmation />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/invoice/:key"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Invoice />}
                      />
                    }
                  />

                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/payments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payments />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/admins"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Admins />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
