const getError = (err) => {
  let error = err && err.response ? err.response?.data?.data : null;
  if (error) {
    console.log(error);
  }
  if (error) {
    if (error.errors) {
      let firstError = error.errors[0]?.message;
      return firstError;
    } else if (error.message) {
      return error.message;
    }
  }
};
export default getError;
