export const siteInfo = {
  name: "Travel Accounting",
  navColor: "#23005a",
  secondary: "#41BCE1",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.5,
  minimum_price: 1000,
  sitekey: "travel_accounting",
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
  cashID: "64f73a274566e6510296ed17",
  by_air: {
    a: 630,
    b: 750,
  },
};
