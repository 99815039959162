import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  CloudServerOutlined,
  DesktopOutlined,
  DollarOutlined,
  GiftOutlined,
  GoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  PictureOutlined,
  SelectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BarcodeOutlined,
  BuildOutlined,
  SlidersOutlined,
  OneToOneOutlined,
  EuroCircleOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  PhoneOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoCashOutline } from "react-icons/io5";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
import {
  MdAccountBalance,
  MdAccountBox,
  MdAdfScanner,
  MdCalendarMonth,
  MdFlight,
  MdFlightTakeoff,
  MdHome,
  MdMonetizationOn,
  MdOutlineBarChart,
  MdOutlineExitToApp,
  MdPeopleAlt,
  MdPerson,
  MdPrivacyTip,
  MdStoreMallDirectory,
  MdViewInAr,
  MdAspectRatid,
  MdWavingHand,
  MdCode,
  MdDonutLarge,
} from "react-icons/md";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const roles = useSelector((state) => state.auth.profile?.roles);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const menuStyle = {
    fontSize: "20px",
    width: "20px",
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[path.toString()]}
      defaultOpenKeys={["accounts"]}
    >
      <Menu.Item key={"/"} icon={<MdHome style={menuStyle} />}>
        <Link to={"/"}>Dashboard</Link>
      </Menu.Item>
      <Menu.Item key={"/customers"} icon={<MdPeopleAlt style={menuStyle} />}>
        <Link to={"/customers"}>Customers</Link>
      </Menu.Item>
      <Menu.Item key={"/booking"} icon={<MdFlight style={menuStyle} />}>
        <Link to={"/booking"}>Bookings</Link>
      </Menu.Item>
      {isPermitted(roles, ["admin", "reservation_officer"]) && (
        <Menu.Item
          key={"/manage-booking"}
          icon={<MdFlightTakeoff style={menuStyle} />}
        >
          <Link to={"/manage-booking"}>New Booking</Link>
        </Menu.Item>
      )}
      <Menu.Item
        key={"/vendors"}
        icon={<MdStoreMallDirectory style={menuStyle} />}
      >
        <Link to={"/vendors"}>Vendors</Link>
      </Menu.Item>
      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item
          key={"/vendor-counter"}
          icon={<MdAdfScanner style={menuStyle} />}
        >
          <Link to={"/vendor-counter"}>Vendor Counter</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item
          key={"/customer-counter"}
          icon={<MdAccountBox style={menuStyle} />}
        >
          <Link to={"/customer-counter"}>Customer Counter</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item
          key={"/payments"}
          icon={<MdMonetizationOn style={menuStyle} />}
        >
          <Link to={"/payments"}>Payments</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item key={"/banks"} icon={<MdAccountBalance style={menuStyle} />}>
          <Link to={"/banks"}>Banks</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin"]) && (
        <Menu.Item
          key={"/admins"}
          icon={<MdPrivacyTip style={{ fontSize: "18px", width: "18px" }} />}
        >
          <Link to={"/admins"}>Admins</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin"]) && (
        <SubMenu
          key="reports"
          icon={<MdOutlineBarChart style={menuStyle} />}
          title="Reports"
        >
          <Menu.Item
            key={"/report/booking"}
            icon={<MdCalendarMonth style={menuStyle} />}
          >
            <Link to={"/report/booking"}>Booking Report</Link>
          </Menu.Item>
          <Menu.Item
            key={"/report/segment"}
            icon={<MdCode style={menuStyle} />}
          >
            <Link to={"/report/segment"}>Segment Report</Link>
          </Menu.Item>
          <Menu.Item
            key={"/report/payment"}
            icon={<MdDonutLarge style={menuStyle} />}
          >
            <Link to={"/report/payment"}>Payment Report</Link>
          </Menu.Item>
        </SubMenu>
      )}

      <Menu.Item
        key="/SettingOutlined"
        icon={<MdPerson style={{ fontSize: "25px", width: "22px" }} />}
      >
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="/logout"
        onClick={() => {
          dispatch(logoutAdmin(() => {}));
          openNotificationWithIcon(
            "success",
            "Success",
            "Logged out successfully"
          );
        }}
        icon={
          <MdOutlineExitToApp style={{ fontSize: "20px", width: "18px" }} />
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
